import { useNavigate, useRoutes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// local imports
import { setgCurrentForm } from "../Redux/features/AppUtilsSlice";
import { PrivateRoutes, PublicRoutes } from "./routes";
import Spinner from "../components/common/Spinner";
import FallBackLoading from "../components/common/FallBackLoading";
import {
  getCompantId,
  getToken,
  setTempCompantId,
  verifyToken,
} from "../utils";

import { getUserData, logoutUser } from "../Redux/features/AuthSlice";
import { API } from "../config";
import { useIdleTimer } from "react-idle-timer";

let isTabClosed = false;

function AppRoutes() {
  let PublicElement = useRoutes(PublicRoutes);
  let PrivateElement = useRoutes(PrivateRoutes);

  const { pathname } = useLocation(); // <-- get current location being accessed
  const dispatch = useDispatch();

  const {
    Auth: { Authenticate, currentUser },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pathname.match("inspection-plan")) {
      dispatch(setgCurrentForm("/inspection-plan/view/"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    console.log("current pathname :", pathname);
    return () => {};
  }, [pathname]);

  const navigate = useNavigate();
  const companyId = getCompantId();
  const TOKEN = getToken();

  useEffect(() => {
    if (verifyToken()) {
      setTempCompantId(companyId, TOKEN);
      var { exp } = jwt_decode(TOKEN);
      if (Date.now() >= exp * 1000) {
        dispatch(logoutUser());
      }
      if (Object.keys(currentUser).length === 0) {
        dispatch(getUserData({ token: TOKEN, navigate, from: pathname }));
      }
    }
    if (!verifyToken()) {
      dispatch(logoutUser());
      navigate("/", { state: { authKey: "log-out" } });
    }
  }, [Authenticate, pathname]);

  useEffect(() => {
    API.settings.companyId();
    return () => {};
  }, [companyId]);

  useEffect(() => {
    if (pathname.match("inspection-plan")) {
      // console.log("Location changed : ", pathname);
      dispatch(setgCurrentForm("/inspection-plan/view/"));
    }
  }, [dispatch, pathname]);

  const timeout = 800_000;
  const promptBeforeIdle = 2_000;
  const [state, setState] = React.useState("Active");
  const [remaining, setRemaining] = React.useState(timeout);
  const [open, setOpen] = React.useState(false);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    if (Authenticate) {
      dispatch(logoutUser());
      navigate("/", { state: { authKey: "log-out" } });
      localStorage.setItem("myKey", "true");
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <>
      {!Authenticate ? (
        <Suspense fallback={<Spinner />}>{PublicElement}</Suspense>
      ) : (
        <Suspense fallback={<FallBackLoading />}>{PrivateElement}</Suspense>
      )}
    </>
  );
}

export default AppRoutes;
