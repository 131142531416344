import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { toast } from "react-toastify";
import {
  updateActionType,
  updateQCPointArry,
  updateTree,
} from "./InspectionPlanSlice";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import { setCurrentNodeActionType } from "./AppUtilsSlice";
import { ERROR_MESS } from "../../constant";

const initialState = {
  qcPoint: {},
  checkConditions: [],
  buildConditionEmails: [],
  buildConditionReports: [],
  error: null,
  status: null,
};

// todo
// update tree when qc point added
export const addQCPoint = createAsyncThunk(
  "post/qcPoint",
  async (formData, thunkAPI) => {
    try {
      const response = await API.QCPoint.add(formData);
      const { data, success } = response.data;

      if (success) {
        SHOW_SUCCESS(success, "QC Point added successfully.");

        thunkAPI.dispatch(setCurrentNodeActionType("v"));
        if (data?.stepId) {
          thunkAPI.dispatch(
            updateTree({
              stepId: data?.stepId,
              data: {
                id: data?.id,
                label: data?.title,
                type: "qc",
              },
            })
          );
        }

        thunkAPI.dispatch(
          updateQCPointArry({
            stepId: data?.stepId,
            data: data,
          })
        );
      }

      return { data, success, code: 201 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateQCPoint = createAsyncThunk(
  "put/qcPoint",
  async (formData, thunkAPI) => {
    try {
      const response = await API.QCPoint.update(formData.id, formData.data);
      // console.log(response.data);

      const { data, success } = response.data;

      if (success) {
        SHOW_SUCCESS(true, "QC Point updated successfully.");

        thunkAPI.dispatch(setCurrentNodeActionType("v"));
        if (data?.stepId) {
          thunkAPI.dispatch(
            updateTree({
              stepId: data?.stepId,
              data: {
                tempkey: true,
                id: data?.id,
                label: data?.title,
                type: "qc",
                actionType: "v",
              },
            })
          );
        }

        thunkAPI.dispatch(
          updateQCPointArry({
            stepId: data?.stepId,
            data: data,
          })
        );
      }

      return { data, success, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const fetchOneQCPoint = createAsyncThunk("get/qcPoint", async (id) => {
  try {
    const response = await API.QCPoint.getOne(id);
    // // console.log("from qcPoint:",response.data);

    return response.data.data;
  } catch (error) {
    // // console.log(error.response.data);
    return error.response.data;
  }
});

export const addcheckCondition = createAsyncThunk(
  "post/checkConditon",
  async (formData) => {
    try {
      // console.log(data);
      const response = await API.QCPoint.addCheckCondition(formData);

      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(success, "Condition added successfully");
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }

      return { data, success, code: 201 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllChackConditions = createAsyncThunk(
  "get/chackConditions",
  async (qcId) => {
    try {
      const response = await API.QCPoint.getAllCheckConditions(qcId);
      // console.log("check conditoin : ", response);

      return response.data.data;
    } catch (error) {
      // // console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const deleteCheckCondition = createAsyncThunk(
  "delete/checkCondition",
  async (data) => {
    try {
      const response = await API.QCPoint.deleteCheckCondition(data);
      if (response.data.success) {
        SHOW_SUCCESS(response.data.success, "Condition deletd successfully.");
      }
      return data;
    } catch (error) {
      // // console.log(error.response.data.message);
      if (error) {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return error.response.data;
    }
  }
);

export const getAllReportEmails = createAsyncThunk("ReportEmails", async () => {
  try {
    const response = await API.QCPoint.reportEmails();

    const {
      success,
      message,
      data: { email, report },
    } = response.data;

    return {
      success,
      data: { email, report },
      code: 200,
    };
  } catch (error) {
    // console.log(error.response.data );
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, errors.code[0]);
    } else {
      SHOW_ERROR(true, "Something went wrong!");
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const deleteQCPoint = createAsyncThunk(
  "delete/QCPoint",
  async (data) => {
    try {
      const response = await API.QCPoint.delete(data);
      if (response.data.success) {
        toast.success("QC Point deleted successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return data;
    } catch (error) {
      console.log("error", error);
      SHOW_ERROR(error, ERROR_MESS);
    }
  }
);

const qcPointSlice = createSlice({
  name: "qcPoint",
  initialState,
  reducers: {
    QCPointCleaner: {
      reducer(state, action) {
        // console.log("QCPoint cleaning... ");
        state.qcPoint = {};
        // console.log("QCPoint cleaning done !");
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addQCPoint.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addQCPoint.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;
        if (success && code === 201) {
          state.status = "succeeded";
          state.qcPoint = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(addQCPoint.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        // state.error = action.payload;
      })
      // get plan data reduces
      .addCase(fetchOneQCPoint.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneQCPoint.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions

        // Add any fetched posts to the array
        // // // console.log(action.payload);

        state.qcPoint = action.payload;
      })
      .addCase(fetchOneQCPoint.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })

      .addCase(getAllChackConditions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllChackConditions.fulfilled, (state, action) => {
        state.status = "succeeded";
        // // console.log(action.payload);

        state.checkConditions = action.payload;
      })
      .addCase(getAllChackConditions.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(addcheckCondition.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addcheckCondition.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          state.status = "succeeded";
          state.qcPoint?.alerts.push(data);
        } else {
          state.status = "failed";
        }
      })
      .addCase(addcheckCondition.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(deleteCheckCondition.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCheckCondition.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qcPoint.alerts = state.qcPoint?.alerts?.filter(
          (i) => i.id !== action.payload
        );
      })
      .addCase(deleteCheckCondition.rejected, (state, action) => {
        state.status = "rejected";
      })

      .addCase(getAllReportEmails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllReportEmails.fulfilled, (state, action) => {
        const {
          success,
          code,
          data: { email, report },
        } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.buildConditionEmails = email;
          state.buildConditionReports = report;
        }
      })
      .addCase(getAllReportEmails.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(deleteQCPoint.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteQCPoint.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qcPoint = {};
      })
      .addCase(deleteQCPoint.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});
export const { QCPointCleaner } = qcPointSlice.actions;

export default qcPointSlice.reducer;
