import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { toast } from "react-toastify";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import {
  ERROR_MESS,
  INSPECTION_LOT_CREATE,
  INSPECTION_LOT_DELETE,
  INSPECTION_LOT_UPDATE,
} from "../../constant";

const initialState = {
  lots: [],
  lot: {},
  error: null,
  status: null,
};

export const fetchLots = createAsyncThunk("get/allLots", async (page) => {
  try {
    const response = await API.InspectionLot.getAll({
      key: page ? "page" : "",
      value: page,
    });
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    // // console.log(error.response.data.message);
    return error.response.data;
  }
});

export const syncLots = createAsyncThunk("sync/lots", async () => {
  try {
    const res = await API.InspectionLot.syncLots();
    console.log("sync res", res);
  } catch (error) {
    console.log("sync error", error);
    return error;
  }
});

export const addLots = createAsyncThunk("post/lots", async (formData) => {
  try {
    const response = await API.InspectionLot.add(formData.values);

    const { success, data } = response.data;
    if (success) {
      SHOW_SUCCESS(success, INSPECTION_LOT_CREATE);
      formData.resetForm();
    }
    // console.log(response.data);

    return {
      success,
      data,
      code: 200,
    };
  } catch (error) {
    // // // console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const updateOneLot = createAsyncThunk("update/lot", async (data) => {
  try {
    // // // console.log("from slice :", data);
    const response = await API.InspectionLot.update(data.id, data.finalData);
    SHOW_SUCCESS(response.data.success, INSPECTION_LOT_UPDATE);
    return response.data;
  } catch (error) {
    // // // console.log("FROM SLICE API ERROR", error);
    SHOW_ERROR(error, ERROR_MESS);
    return [];
  }
});

export const deleteLots = createAsyncThunk("delete/lot", async (data) => {
  try {
    const response = await API.InspectionLot.delete(data);
    SHOW_SUCCESS(response.data.success, INSPECTION_LOT_DELETE);
    return data;
  } catch (error) {
    // // console.log(error.response.data.message);
    SHOW_ERROR(error, ERROR_MESS);
    return [];
  }
});

export const fetchOneLot = createAsyncThunk("get/lot", async (id) => {
  try {
    const response = await API.InspectionLot.getOne(id);
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    // // console.log(error.response.data.message);
    return error.response.data;
  }
});

const InspectionLotSlice = createSlice({
  name: "Inspection Lot",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLots.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLots.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lots = action.payload;
        // delete action.payload.lots;
        // state.paginationData = action.payload;
      })
      .addCase(fetchLots.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchOneLot.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneLot.fulfilled, (state, action) => {
        state.status = "succeeded";
        let refineLot = {
          ...action.payload,
          plantId: action.payload.plant?.id,
          userIds: action.payload.assignUsers[0]?.id,
          materialId: action.payload.material?.id,
        };
        console.log("from slice : ", action.payload);
        state.lot = refineLot;
      })
      .addCase(fetchOneLot.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deleteLots.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteLots.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lots = state.lots.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteLots.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(addLots.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addLots.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.lots.unshift(data);
        } else {
          state.status = "failed";
          state.error = code;
        }
      })
      .addCase(addLots.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneLot.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateOneLot.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateOneLot.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      });
  },
});

// export const { postAdded, reactionAdded } = plantSlice.actions;

export default InspectionLotSlice.reducer;
