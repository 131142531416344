import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { API } from "../../config";
import { toast } from "react-toastify";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import _ from "lodash";
import { ERROR_MESS } from "../../constant";

const initialState = {
  allPermissions: [],
  assignedPermissions: [],
  permission: {},
  error: null,
  status: null,
};

export const getAllPermissions = createAsyncThunk(
  "get/allPermissions",
  async () => {
    try {
      const response = await API.Permissions.getAll();
      // // // console.log(response);
      console.log(response.data.data);
      return response.data;
    } catch (error) {
      // // // console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const fetchOneAssignedPermissionData = createAsyncThunk(
  "get/assignedPermission",
  async (id) => {
    try {
      const response = await API.Permissions.getAllAssigned(id);
      const resAllPermissions = await API.Permissions.getAll();
      const ALL_PERM = resAllPermissions.data.data;
      console.log(ALL_PERM);
      let assTempArr = [];
      let assAllTempArr = [];
      const {
        success,
        message,
        data: { assignedPermissions },
      } = response.data;

      if (success) {
        if (assignedPermissions !== undefined) {
          for (const [key, value] of Object.entries(assignedPermissions)) {
            assTempArr.push(parseInt(key));
          }
        }

        ALL_PERM.forEach(
          ({ id, name, is_company, form_name, description }, i, arr) => {
            // console.log(id);
            if (assTempArr.includes(id)) {
              assAllTempArr.push({
                id,
                name,
                hashed: true,
                is_company,
                form_name,
                description,
              });
            } else {
              assAllTempArr.push({
                id,
                name,
                hashed: false,
                is_company,
                form_name,
                description,
              });
            }
          }
        );

        // console.log("assAllTempArr :", assAllTempArr);
      }
      return {
        success,
        data: { selectedArry: assTempArr, allPermissions: assAllTempArr },
        code: 200,
      };
    } catch (error) {
      // console.log(error.response.data );
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const AssignNewPermissions = createAsyncThunk(
  "post/assignPermission",
  async (pData) => {
    try {
      const resAllPermissions = await API.Permissions.getAll();
      const response = await API.Permissions.assignNew(pData.id, pData.data);
      const { success, message, data } = response.data;
      const ALL_PERM = resAllPermissions.data.data;

      SHOW_SUCCESS(success, message);
      ALL_PERM.forEach(({ id, name }, i, arr) => {
        if (pData.data.permissions.includes(id)) {
          ALL_PERM[i] = { id, name, hashed: true };
        } else {
          ALL_PERM[i] = { id, name, hashed: false };
        }
      });

      return {
        success,
        data: {
          selectedArry: pData.data.permissions,
          allPermissions: ALL_PERM,
        },
        code: 201,
      };
    } catch (error) {
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const userPermissionSlice = createSlice({
  name: "userPermissions",
  initialState,
  reducers: {
    setAllPermissions: (state, action) => {
      state.allPermissions = action.payload;
    },
    setAllSelectedPermissions: (state, action) => {
      state.assignedPermissions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allPermissions = action.payload.data;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // get permission data reduces
      .addCase(fetchOneAssignedPermissionData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneAssignedPermissionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        const {
          data: { allPermissions, selectedArry },
        } = action.payload;

        state.allPermissions = allPermissions;
        state.assignedPermissions = selectedArry;
      })

      .addCase(fetchOneAssignedPermissionData.rejected, (state, action) => {
        state.status = "failed";
        console.log(action.payload);
        // state.error = action.payload;
      })
      // delete permission data reduces

      .addCase(AssignNewPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(AssignNewPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const {
          data: { allPermissions, selectedArry },
        } = action.payload;

        state.allPermissions = allPermissions;
        state.assignedPermissions = selectedArry;
      })
      .addCase(AssignNewPermissions.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      });
  },
});

export const { setAllPermissions, setAllSelectedPermissions } =
  userPermissionSlice.actions;

export default userPermissionSlice.reducer;
