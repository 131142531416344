import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../config";

const initialState = {
  files: {},
  error: null,
  status: null,
};

export const uploadFile = createAsyncThunk(
  "post/file",
  async ({ data, onResponse }) => {
    try {
      const response = await API.file.upload(data);
      if (response.data.success) {
        toast.success("File attached successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      if (error) {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return error.response.data;
    }
  }
);

export const deleteFile = createAsyncThunk("delete/file", async () => {
  const file = initialState.files;
  return file;
});

const FileSlice = createSlice({
  name: "stripe charges",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(uploadFile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.files = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteFile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.files = initialState.files;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default FileSlice.reducer;
