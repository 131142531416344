import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../config";
import {
  ERROR_MESS,
  ROLE_CREATE,
  ROLE_DELETE,
  ROLE_UPDATE,
} from "../../constant";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";

const initialState = {
  Roles: [],
  Role: {},
  error: null,
  status: null,
};

export const getAllRoles = createAsyncThunk("get/Roles", async () => {
  try {
    const response = await API.Roles.getAll();
    // // // console.log(response);
    // // console.log(response.data.data);
    // let rolesData = [];

    // if (formData.compantFlag) {
    //   rolesData = response.data.data.filter((per) => per?.isCompany === false);
    // }

    // if (!formData.compantFlag) {
    //   rolesData = response.data.data.filter((per) => per?.isCompany === true);
    // }
    // console.log(rolesData);
    return response.data.data;
  } catch (error) {
    console.log(error.response.message);
    return error.response;
  }
});
export const addRolesData = createAsyncThunk("post/Roles", async (data) => {
  try {
    const response = await API.Roles.add(data.values);
    if (response.data.success) {
      SHOW_SUCCESS(response.data.success, ROLE_CREATE);
      data.resetForm();
    }

    return [];
  } catch (error) {
    // // // console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422 && errors.hasOwnProperty("name")) {
      SHOW_ERROR(true, errors.name[0]);
    } else {
      SHOW_ERROR(error, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const updateOneRole = createAsyncThunk("update/Role", async (data) => {
  try {
    // // console.log("from slice :", data);
    const response = await API.Roles.update(data.id, data.finalData);
    SHOW_SUCCESS(response.data.success, ROLE_UPDATE);
    return [];
  } catch (error) {
    // // console.log("FROM SLICE API ERROR", error);
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422 && errors.hasOwnProperty("name")) {
      SHOW_ERROR(true, errors.name[0]);
    } else {
      SHOW_ERROR(error, ERROR_MESS);
    }
    return [];
  }
});

export const deleteRole = createAsyncThunk("delete/Role", async (data) => {
  try {
    const response = await API.Roles.delete(data);
    SHOW_SUCCESS(response.data.success, ROLE_DELETE);
    return data;
  } catch (error) {
    // // console.log(error.response.data.message);
    SHOW_ERROR(error, ERROR_MESS);
    return [];
  }
});
export const fetchOneRoleData = createAsyncThunk("get/Role", async (id) => {
  try {
    const response = await API.Roles.getOne(id);
    // // console.log(response.data);

    return response.data;
  } catch (error) {
    // // console.log(error.response.data.message);
    return error.response.data;
  }
});

// export const addNewPost = createAsyncThunk('posts/addNewPost', async (initialPost) => {
//   const response = await axios.post(POSTS_URL, initialPost)
//   return response.data
// })

const RoleSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions

        // Add any fetched posts to the array
        // // // console.log(action.payload.inspectionPlans);
        state.Roles = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // post data reduces
      .addCase(addRolesData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addRolesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        // // // console.log(action.payload);
      })
      .addCase(addRolesData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchOneRoleData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneRoleData.fulfilled, (state, action) => {
        state.status = "succeeded";
        // // // console.log(action.payload);
        state.Role = action.payload.data;
      })
      .addCase(fetchOneRoleData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // delete plan data reduces
      .addCase(deleteRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions
        state.Roles = state.Roles.filter((item) => item.id !== action.payload);
        // Add any fetched posts to the array
        // // // console.log(action.payload);
        // state.plans = action.payload.inspectionPlans;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      });
  },
});

export default RoleSlice.reducer;
