import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans } from "../../../Redux/features/PlansSlice";
import { getIntent } from "../../../Redux/features/StripeSlice";
import ToggleButton from "react-toggle-button";
// local imoprts
import SinglePlan from "./components/SinglePlan";

const CompanyPlanList = () => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();

  const { Plans, error, status } = useSelector((state) => state.Plan);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col
          md={12}
          className="d-flex flex-col justify-content-center mt-4 mb-2"
        >
          <h2>Subscribe our plans </h2>
        </Col>
        <Col md={12} className="text-center">
          <label className="switch switch-lg switch-primary text-center mb-2 me-5 pe-4">
            <input
              type="checkbox"
              className="switch-input"
              checked={toggle.isActive}
              onChange={(e) => {
                setToggle(!toggle);
              }}
            />
            <span style={{ width: "80px" }} className="switch-toggle-slider">
              <span className="switch-on fs-6">Monthly</span>
              <span className="switch-off fs-6">Yearly</span>
            </span>
          </label>
        </Col>
      </Row>
      <Row>
        {Plans.map((plan, idx) => (
          <Col lg={4} key={idx}>
            <SinglePlan check={toggle} plan={plan} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CompanyPlanList;
