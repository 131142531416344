import EAPI from "../services/api";
import timeZone from "../data/timeZone.json";

//comment
export const key = {
  server: {
    url: process.env.REACT_APP_BASE_API_URL,
    // api: "https://piuat.techandover.com/api",
    //api: "https://dev-backend.processintel.com/api",
    api: "https://qas-backend.auditsbyte.com/api",

    // api: `${process.env.REACT_APP_BASE_API_URL}/api`,
  },
  app: {
    name: process.env.REACT_APP_NAME,
    url: process.env.REACT_APP_URL,
  },

  tree: {
    ACTION_TYPES: ["e", "a"],
  },
  apiKey: { tinyEditor: process.env.REACT_APP_TINYMCE_API_KEY },
  auth: {
    clien_secret: process.env.REACT_APP_CLIENT_SECRET,
    client_id: process.env.REACT_APP_CLIEN_ID,
    grant_type: process.env.REACT_APP_GRANT_TYPE,
  },
  constant: {
    TIME_ZONE: timeZone,
  },
  token: null,
  compnyId: null,

  // {label:"", link:"", icon:""}
  sideBar: {
    sassMenuLink: [
      // {
      //   link: "/",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Dashboard",
      //   permission: "Dashboard-View",
      // },
      {
        link: "/company",
        icon: "/assets/img/inspection-plan.svg",
        label: "Company Management",
        permission: "Company-View",
      },
      {
        link: "/companyPlans",
        icon: "/assets/img/inspection-plan.svg",
        label: "Company Plans",
        permission: "Company-View",
      },
      {
        link: "/subscription",
        icon: "/assets/img/inspection-plan.svg",
        label: "Subscription Plan",
        permission: "Plan-View",
      },
      // {
      //   link: "/adminRoles",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Role Management",
      //   permission: "Roles-View",
      // },
      {
        link: "/transaction",
        icon: "/assets/img/inspection-plan.svg",
        label: "Transaction History",
        permission: "Transaction-View",
      },

      // {
      //   link: "/consent",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Registration Consent",
      //   permission: "Registration-update",
      // },
      // {
      //   link: "/plan-subscription",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Plan Subscription",
      //   // permission: "Plan-Create",
      // },
      // // {
      //   link: "/payment-method",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Payment Method",
      //   permission: "PaymentMethod-Add",
      // },
    ],
    companyMenuLink: [
      {
        link: "/",
        icon: "/assets/img/inspection-plan.svg",
        label: "Dashboard",
        permission: "Dashboard-View",
      },

      {
        link: "/inspection-plan",
        icon: "/assets/img/inspection-plan.svg",
        label: "Inspection Plan",
        permission: "InspectionPlan-View",
      },
      {
        link: "/build-area",
        icon: "",
        label: "Build Area",
        permission: "BuildArea-View",
      },
      {
        link: "/material",
        icon: "/assets/img/material.svg",
        label: "Material Master",
        permission: "MaterialMaster-View",
      },
      {
        link: "/station",
        icon: "/assets/img/work-station.svg",
        label: "Work Station",
        permission: "WorkStation-View",
      },
      {
        link: "/plant",
        icon: "/assets/img/plant.svg",
        label: "Plant",
        permission: "Plant-View",
      },
      {
        link: "/Inspection-lot",
        icon: "/assets/img/inspection-lot.svg",
        label: "Inspection Lot",
        permission: "InspectionLot-View",
      },
      {
        link: "/form-builder",
        icon: "/assets/img/inspection-plan.svg",
        label: "Forms",
        permission: "Forms-View",
      },
      {
        link: "/group/list",
        icon: "/assets/img/inspection-plan.svg",
        label: "Approval Group",
        permission: "ApprovalGroup-View",
      },
      {
        link: "/fixed-fields",
        icon: "/assets/img/inspection-plan.svg",
        label: "Approval Form",
        permission: "Form-Create",
      },
      // {
      //   link: "/plan-upgrade",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Plan Upgrade",
      //   permission: "Plan-Upgrade",
      // },
      {
        link: "submenu",
        icon: "/assets/img/settings.svg",
        label: "Settings",
        permission: [
          "RejectionReason-View",
          "User-View",
          "RoleManagement-View",
          "MaterialNoConfig-View",
          "MeasurementUnits-View",
          "Adminlog-View",
          "Group-View",
        ],
        subMenu: [
          {
            link: "/rejection-reasons",
            icon: "/assets/img/inspection-plan.svg",
            label: "Rejection Reason",
            permission: "RejectionReason-View",
          },
          {
            link: "/user",
            icon: "/assets/img/users.svg",
            label: "Users",
            permission: "User-View",
          },
          {
            link: "/companyRoles",
            icon: "/assets/img/inspection-plan.svg",
            label: "Role Management",
            permission: "RoleManagement-View",
          },
          {
            link: "/approval-categories",
            icon: "/assets/img/inspection-plan.svg",
            label: "Approval Category",
            permission: "ApprovalCategories-View",
          },
          {
            link: "/material-no-config",
            icon: "/assets/img/material-config.svg",
            label: "Material No Config",
            permission: "MaterialNoConfig-View",
          },
          {
            link: "/measurement-units",
            icon: "/assets/img/inspection-plan.svg",
            label: "Measurement Unit",
            permission: "MeasurementUnits-View",
          },
          {
            link: "/admin-log",
            icon: "/assets/img/admin-log.svg",
            label: "Admin Logs",
            permission: "Adminlog-View",
          },
          // {
          //   link: "/account",
          //   icon: "/assets/img/admin-info.svg",
          //   label: "Account Info",
          // },
          // {
          //   link: "/company-edit",
          //   icon: "/assets/img/edit-company.svg",
          //   label: "Company Info",
          // },
          {
            link: "/groups",
            icon: "/assets/img/role-managment.svg",
            label: "Group",
            permission: "Group-View",
          },
        ],
      },
      {
        link: "/sensor",
        icon: "/assets/img/sensor.svg",
        label: "Sensor",
        permission: "Sensor-View",
      },
      {
        link: "/sensor-type",
        icon: "/assets/img/sensor.svg",
        label: "Sensor Type",
        permission: "SensorType-View",
      },
      {
        link: "reportSubmenu",
        icon: "/assets/img/inspection-plan.svg",
        label: "Report",
        permission: [
          "Dashboard-View",
          "GeneralForm-View",
          "Form-Trail",
          "Form-Trail-View",
          "InspectionLotReport-View",
        ],
        subMenu: [
          // {
          //   link: "/report/graph",
          //   icon: "/assets/img/inspection-plan.svg",
          //   label: "Graphs",
          //   permission: "Graph-Filter",
          // },
          {
            link: "/reportsDashboard",
            icon: "/assets/img/inspection-plan.svg",
            label: "Graphs",
            permission: "Dashboard-View",
          },
          {
            link: "/report/general-form",
            icon: "/assets/img/inspection-plan.svg",
            label: "General Form",
            permission: "GeneralForm-View",
          },
          {
            link: "/userEnableForm",
            icon: "/assets/img/inspection-plan.svg",
            label: "Flow Forms Trail",
            permission: "Form-Trail",
          },
          {
            link: "/FixedFormDetail",
            icon: "/assets/img/inspection-plan.svg",
            label: "Approval Trail",
            permission: "Form-Trail-View",
          },
          // {
          //   link: "/FMEA",
          //   icon: "/assets/img/inspection-plan.svg",
          //   label: "FMEA",
          //   permission: "FMEA-View",
          // },
          // {
          //   link: "/report/fema",
          //   icon: "/assets/img/inspection-plan.svg",
          //   label: "Fema",
          //   permission: "FMEA-Report-View",
          // },
          {
            link: "/report/fishbone",
            icon: "/assets/img/inspection-plan.svg",
            label: "Fishbone",
            permission: "Fishbone-View",
          },
          // {
          //   link: "/report/5why",
          //   icon: "/assets/img/inspection-plan.svg",
          //   label: "5Ys",
          //   permission: "Report2-View",
          // },
          {
            link: "/inspection-lot-report",
            icon: "/assets/img/inspection-plan.svg",
            label: "Inspection Lot Report",
            permission: "InspectionLotReport-View",
          },
        ],
      },
    ],
  },

  form: {
    WORK_FLOW: [
      { id: 1, type: "Flow Forms" },
      { id: 0, type: "Entry Forms" },
    ],

    TYPES: [
      { type: 1, title: "Single Line Text Box" },
      { type: 2, title: "Paragraph Line Text Box" },
      { type: 3, title: "Drop Down" },
      { type: 4, title: "Check Box" },
      { type: 5, title: "Radio Button" },
    ],
  },

  approval: {
    ROLE: [
      { id: "R", title: "Viewer" },
      { id: "A", title: "Approver" },
    ],
  },

  initialState: {
    STEP: {
      ERROR: {
        title: null,
        orderNo: null,
        workstationIds: null,
      },
      STATE: {
        title: "",
        orderNo: "",
        description: "",
        workstationIds: "",
        workstation: [],
        goodImage: "",
      },
    },
    BUILD_CONDITION: {
      ERROR: {
        type: null,
        operator: null,
        frequency: null,
        noOfIteration: null,
        emails: null,
      },
    },

    QC_POINT: {
      STATE: {
        title: "",
        description: "",
        order: "",
        x: "",
        y: "",
        isMeasurement: "",
        passMinRange: "",
        passMaxRange: "",
        measurementUnitId: "",
      },
    },

    ALERT_CONDITION: {
      STATE: {
        type: "",
        operator: "",
        value: "",
        frequency: "",
        noOfIteration: "",
        action: "",
        emails: "",
      },
    },
  },

  permissions: {
    CompanyManagement: {
      CREATE: "Company-Create",
      VIEW: "Company-View",
      SELECT: "Company-Select",
      DELETE: "Company-Delete",
    },
    Plans: {
      CREATE: "Plans-Create",
      PURCHASE: "Plan-Purchase",
      EDIT: "Plan-Edit",
      DELETE: "Plan-Delete",
      VIEW: "Plan-View",
    },
    AdminRoleManagement: {
      CREATE: "Roles-Create",
      VIEW: "Roles-View",
      EDIT: "Roles-Edit",
      DELETE: "Roles-Delete",
    },
    ManageTransaction: {
      CREATE: "Transaction-Create",
      VIEW: "Transaction-View",
    },
    RegistrationConsent: { UPDATE: "Registration-update" },
    PlanSubscription: { CREATE: "Plan-Create" },
    PlanUpgrade: { UPGRADE: "Plan-Upgrade" },
    PaymentMethod: { ADD: "PaymentMethod-Add" },
    InspectionPlan: {
      CREATE: "InspectionPlan-Create",
      VIEW: "InspectionPlan-View",
      DELETE: "InspectionPlan-Delete",
    },
    BuildArea: {
      CREATE: "BuildArea-Create",
      VIEW: "BuildArea-View",
      DELETE: "BuildArea-Delete",
    },
    MaterialMaster: {
      CREATE: "MaterialMaster-Create",
      VIEW: "MaterialMaster-View",
      DELETE: "MaterialMaster-Delete",
    },
    WorkStation: {
      CREATE: "WorkStation-Create",
      VIEW: "WorkStation-View",
      DELETE: "WorkStation-Delete",
    },
    Plants: {
      CREATE: "Plant-Create",
      VIEW: "Plant-View",
      DELETE: "Plant-Delete",
    },
    InspectionLot: {
      CREATE: "InspectionLot-Create",
      VIEW: "InspectionLot-View",
      DELETE: "InspectionLot-Delete",
    },
    GeneralWorkflow: { VIEW: "Forms-View" },
    ApprovalTrails: { TRAIL: "Form-Trail", TRAILS_VIEW: "Form-Trail-View" },
    ApprovalGroups: {
      CREATE: "ApprovalGroup-Create",
      ADD: "ApprovalGroup-Add",
      VIEW: "ApprovalGroup-View",
    },
    User: {
      CREATE: "User-Create",
      VIEW: "User-View",
      EDIT: "User-Edit",
      DELETE: "User-Delete",
    },
    RoleManagement: {
      CREATE: "RoleManagement-Create",
      VIEW: "RoleManagement-View",
      EDIT: "RoleManagement-Edit",
      DELETE: "RoleManagement-Delete",
    },
    Workflow: { CREATE: "Workflow-Ceate", VIEW: "Workflow-View" },
    EnabledTrails: { TRAIL: "Enabled-Trail" },
    ApprovalForm: { CREATE: "Form-Create" },
    RejectionReason: {
      CREATE: "RejectionReason-Create",
      VIEW: "RejectionReason-View",
      DELETE: "RejectionReason-Delete",
      CHILD: "RejectionReason-Child",
    },
    MaterialNoConfig: { VIEW: "MaterialNoConfig-View" },
    MeasurementUnits: {
      CREATE: "MeasurementUnits-Create",
      VIEW: "MeasurementUnits-View",
      DELETE: "MeasurementUnits-Delete",
    },
    AdminLogs: { VIEW: "AdminLogs-View" },
    Groups: {
      CREATE: "Group-Create",
      VIEW: "Group-View",
      DELETE: "Group-Delete",
    },
    Sensors: {
      CREATE: "Sensor-Create",
      VIEW: "Sensor-View",
      DELETE: "Sensor-Delete",
    },
    SensorType: {
      CREATE: "SensorType-Create",
      VIEW: "SensorType-View",
      DELETE: "SensorType-Delete",
    },
    Graphs: { FILTER: "Graph-Filter" },
    GeneralForm: { VIEW: "GeneralForm-View" },
    InspectionLotReport: { VIEW: "InspectionLotReport-View" },
    Dashboard: { VIEW: "Dashboard-View" },
    Permissions: { EDIT: "Permission-Edit", VIEW: "Permission-View" },
    Settings: { VIEW: "Settings-View" },
    Report: { VIEW: "Report-View" },
    FMEA: { VIEW: "FMEA-View" },
    FMEAReport: { VIEW: "FMEA-Report-View" },
    Report1: { VIEW: "Report1-View" },
    Report2: { VIEW: "Report2-View" },
  },
};

export const API = new EAPI({ BaseUrl: key.server.api });
