import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { ERROR_MESS } from "../../constant";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";

const initialState = {
  fema: {},
  fishBone: [],
  fishBoneReport: [],
  fiveYs: {},
  material: [],
  error: null,
  status: null,
};

// parentRejectedReason: "MAN"
// parentRejectedReasonId: 28
// rejectedReason: "Shift"
// rejectedReasonId: 29
// total: 1

// id(pin):28
// code(pin):"1001"
// reason(pin):"MAN"
// parent(pin):""
export const getAllFishboneReport = createAsyncThunk(
  "get/fishbone",
  async (num) => {
    try {
      const rejectionResponse = await API.rejectedReasons.getAll({
        key: "",
        value: "",
      });
      const response = await API.report.getFishBone(num);
      const reasonsData = rejectionResponse.data.data;
      const { success, data } = response.data;

      let refineReasons = reasonsData?.filter((RR) => RR?.parent === "");

      refineReasons.forEach((RR) => {
        data.forEach((RD) => {
          if (
            RD.parentRejectedReason === RR.reason &&
            RD.parentRejectedReasonId === RR.id
          ) {
            RR.child.forEach((SC) => {
              if (
                RD.rejectedReason === SC.reason &&
                RD.rejectedReasonId === SC.id
              ) {
                SC.value = RD.total;
                console.log("RD => ", RD);
              }
              // else {
              //   SC.value = 0;
              // }
            });
          }
          // else if (
          //   RD.parentRejectedReason !== RR.reason &&
          //   RD.parentRejectedReasonId !== RR.id
          // ) {
          //   RR.child.forEach((SC) => {
          //     SC.value = 0;
          //   });
          // }
        });
      });

      return { success, data: refineReasons, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllFishboneMaterialReport = createAsyncThunk(
  "get/Material",
  async (num) => {
    try {
      const response = await API.report.getFishBoneMaterial(num);
      const { success, data } = response.data;
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllFishBoneReports = createAsyncThunk(
  "get/reports/fish-bone/msad",
  async () => {
    try {
      const response = await API.report.getAllFiveWhyReports();
      return response.data.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllFiveWhyReports = createAsyncThunk(
  "get/reports/five-why",
  async (id) => {
    try {
      const response = await API.report.fiveWhy.getAll(id);
      const { success, data } = response.data;
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateFiveWhyReport = createAsyncThunk(
  "put/reports/five-why",
  async (formData) => {
    console.log("data is", formData);

    try {
      const response = await API.report.fiveWhy.update(
        formData.id,
        formData.finalData
      );
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(true, "5Ys updated successfully");
      }
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllFemaReport = createAsyncThunk("get/FEMA", async (id) => {
  try {
    const response = await API.report.fema.getAll(id);
    const { success, data } = response.data;
    return { success, data, code: 200 };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const updateFemaReport = createAsyncThunk(
  "put/femaReport",
  async (formData) => {
    try {
      const response = await API.report.fema.update(
        formData.id,
        formData.finalData
      );
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(true, "FMEA updated successfully");
      }
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const ReportSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllFishboneReport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllFishboneReport.fulfilled, (state, action) => {
        const { success, code, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.fishBoneReport = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getAllFishboneReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllFishboneMaterialReport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllFishboneMaterialReport.fulfilled, (state, action) => {
        const { success, code, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.material = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getAllFishboneMaterialReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllFiveWhyReports.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllFiveWhyReports.fulfilled, (state, action) => {
        const { success, code, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.fiveYs = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getAllFiveWhyReports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateFiveWhyReport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateFiveWhyReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fiveYs = action.payload.data;
      })
      .addCase(updateFiveWhyReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllFemaReport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllFemaReport.fulfilled, (state, action) => {
        const { success, code, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.fema = data.fmea;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getAllFemaReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(updateFemaReport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateFemaReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.fema = action.payload.data;
      })
      .addCase(updateFemaReport.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default ReportSlice.reducer;
// const grid = sheetData?.map((row) => [...row]);
// changes.forEach(({ cell, row, col, value }) => {
//   grid[row][col] = { ...grid[row][col], value };
// });
// setSheetData({ grid });
