import React from "react";
import { NavLink } from "react-router-dom";
import { ImTree } from "react-icons/im";
import { useSelector } from "react-redux";

const LinkItem = ({ link, label, icon, permission }) => {
  const { permissions } = useSelector((state) => state.Auth);

  return (
    <>
      {/* {permissions?.includes(permission) ? ( */}
      <NavLink activeClassName="active" to={link} className="menu-link ">
        {icon.includes("svg") ? (
          <img
            src={icon}
            className="menu-icon tf-icons svg-icon"
            alt="sidebar menu icon"
          />
        ) : label.includes("Build Area") ? (
          <ImTree className="menu-icon tf-icons " />
        ) : (
          <i className={icon} />
        )}
        <div>{label}</div>
      </NavLink>
      {/* ) : null} */}
    </>
  );
};

export default LinkItem;
