import React, { useCallback, useState, useEffect } from "react";
import produce from "immer";
// local imports

import Footer from "../layout/components/Footer";
import Tree from "../Tree";
import MUITree from "../Tree/MUITree";
import DATA from "../../data/tree2.json";
import { useDispatch } from "react-redux";
import { fetchInspectionPlans } from "../../Redux/features/InspectionPlanSlice";
import CUTable from "../common/CUTable";

const Playground = () => {
  const [treeData, setTreeData] = useState(DATA);
  const [currentNode, setCurrentNode] = useState({});
  const dispatch = useDispatch();

  const handleToggle = useCallback((id) => {
    setTreeData(
      produce((draft) => {
        const form = draft.find((f) => f.id === "6");
        // // // console.log(form.D);
        form.children = [
          {
            id: "9",
            label: "cellphone",
          },
        ];
      })
    );
  }, []);

  return (
    <>
      {/* <div className="d-flex justify-content-center align-itms-center flex-nowrap">
        <Tree data={treeData} setCurrentNode={setCurrentNode} />
        <MUITree/>
        <div className="container">
          <button onClick={handleToggle} className="btn btn-sm btn-success m-2">
            add child
          </button>
          <button
            onClick={() => {
              setTreeData([
                ...treeData,
                { id: 12 * Math.random(), label: "Food" },
              ]);
            }}
            className="btn btn-sm btn-danger m-2"
          >
            add parent
          </button>

          <button
            onClick={() => {
              dispatch(fetchInspectionPlans());
            }}
            className="btn btn-sm btn-warning m-2"
          >
            send req
          </button>
        </div>
        <CUTable  data={} heading={headings}/>
      </div> */}
      {/* Content wrapper  */}
      <div className="content-wrapper">
        {/* Content */}

        <div className="container-xxl flex-grow-1 px-0">
          <div className="row">
            {/* Latest Update */}
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between mb-3">
                  <h5 style={{ fontFamily: "Rubik, sans-serif" }} className="card-title mb-0">Latest Update</h5>
                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-outline-secondary dropdown-toggle"
                      type="button"
                      id="orederStatistics"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      2021
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="orederStatistics"
                    >
                      <a className="dropdown-item" href="javascript:void(0);">
                        2020
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        2021
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        2022
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body mb-3">
                  <ul className="p-0 m-0">
                    <li className="d-flex mb-4">
                      <div className="avatar avatar-sm flex-shrink-0 me-3">
                        <span className="avatar-initial rounded-circle bg-label-primary">
                          <i className="bx bx-cube"></i>
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div className="me-2">
                          <p className="mb-0 lh-1">Inspection Lots Created</p>
                          <small className="text-muted">2k New Products</small>
                        </div>
                        <div className="item-progress">10</div>
                      </div>
                    </li>
                    <li className="d-flex mb-4">
                      <div className="avatar avatar-sm flex-shrink-0 me-3">
                        <span className="avatar-initial rounded-circle bg-label-info">
                          <i className="bx bx-pie-chart-alt"></i>
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div className="me-2">
                          <p className="mb-0 lh-1">
                            Inspection Lots Under <br /> Review
                          </p>
                          <small className="text-muted">39k New Sales</small>
                        </div>
                        <div className="item-progress">26</div>
                      </div>
                    </li>
                    <li className="d-flex mb-4">
                      <div className="avatar avatar-sm flex-shrink-0 me-3">
                        <span className="avatar-initial rounded-circle bg-label-danger">
                          <i className="bx bx-credit-card"></i>
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div className="me-2">
                          <p className="mb-0 lh-1">Inspection Lots Accepted</p>
                          <small className="text-muted">43k New Revenue</small>
                        </div>
                        <div className="item-progress">15</div>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="avatar avatar-sm flex-shrink-0 me-3">
                        <span className="avatar-initial rounded-circle bg-label-success">
                          <i className="bx bx-cart"></i>
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div className="me-2">
                          <p className="mb-0 lh-1">Inspection Lots Rejected</p>
                          <small className="text-muted">Total Expenses</small>
                        </div>
                        <div className="item-progress">2</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* / Latest Update */}
            {/* All Users  */}
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card h-100">
                <div className="card-header">
                  <h5 style={{ fontFamily: "Rubik, sans-serif" }} className="card-title mb-2">
                    Top 3 Inspectors
                  </h5>
                </div>
                <div className="card-body">
                  <ul className="p-0 m-0">
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-success me-2"></span>{" "}
                        Mark X
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>29.5k</span> */}
                        <span className="fw-semibold">56</span>
                      </div>
                    </li>
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-danger me-2"></span>{" "}
                        Reborn
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>25.7k</span> */}
                        <span className="fw-semibold">26</span>
                      </div>
                    </li>
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-info me-2"></span>{" "}
                        Carnival
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>11.5k</span> */}
                        <span className="fw-semibold">34</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* / All Users  */}

            {/* Statistics cards & Revenue Growth Chart */}
            <div className="col-lg-4 col-12">
              <div className="row">
                {/* Statistics Cards */}
                <div className="col-6 col-md-3 col-lg-6 mb-4">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <div className="avatar mx-auto mb-2">
                        <span className="avatar-initial rounded-circle bg-label-success">
                          <i className="bx bx-purchase-tag fs-4"></i>
                        </span>
                      </div>
                      <span className="d-block">Total Inspectors Assigned</span>
                      <h2 className="mb-0 fw-bolder">65</h2>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-lg-6 mb-4">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <div className="avatar mx-auto mb-2">
                        <span className="avatar-initial rounded-circle bg-label-danger">
                          <i className="bx bx-cart fs-4"></i>
                        </span>
                      </div>
                      <span className="d-block">Total Inspection Plans</span>
                      <h2 className="mb-0 fw-bolder">40</h2>
                    </div>
                  </div>
                </div>
                {/* Statistics Cards */}
                {/* Revenue Growth Chart */}
                <div className="col-12 col-md-6 col-lg-12 mb-4">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center pb-0">
                      <h5 style={{ fontFamily: "Rubik, sans-serif" }} className="card-title mb-0">Total Users</h5>
                      {/* <span>$25,980</span> */}
                    </div>
                    <div className="card-body pb-0 text-center">
                      <h2
                        style={{ marginTop: "2.7rem", marginBottom: "2.7rem" }}
                        className="fw-bolder"
                      >
                        90
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Revenue Growth Chart */}
              </div>
            </div>
            {/* Statistics cards & Revenue Growth Chart */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Playground;
