import React, { useEffect } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  createStripeCharge,
  getIntent,
} from "../../../../../Redux/features/StripeSlice";
import { SHOW_ERROR } from "../../../../../utils/toastMessages";
import Spinner from "../../../../../components/common/Spinner";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      with: "100%",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};
const CheckoutForm = (props) => {
  const navigate = useNavigate();

  const { stripe, elements } = props;
  const {
    Stripe: { intent, PPlan_id, registeredCompany, status },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIntent());
    // console.log("registeredCompany => ", companyId);
    return () => {};
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements) {
      return;
    }
    try {
      const { setupIntent, error } = await stripe.handleCardSetup(
        intent,
        cardElement
      );
      // console.log("Payment method : ", setupIntent);
      if (setupIntent?.status === "succeeded") {
        let payData = {
          ...PPlan_id,
          paymentMethod: setupIntent?.payment_method,
          customerId: setupIntent?.id,
          contactPersonEmail: registeredCompany.contactPersonEmail,
          companyName: registeredCompany.name,
          contactPersonFirstName: registeredCompany.contactPersonFirstName,
          contactPersonLastName: registeredCompany.contactPersonLastName,
          contactPersonPhoneNo: registeredCompany.contactPersonPhoneNo,
        };

        // payData.companyId = companyId ||"not found"
        // console.log("payData => ", payData);

        dispatch(
          createStripeCharge({ payData, navigate, hide: props.onHide() })
        );
      } else {
        if (error?.code === "card_declined") {
          SHOW_ERROR(true, error?.message);
        } else {
          SHOW_ERROR(true, "payment failed");
        }

        // console.log("error => ", error);
        // props.onHide();
        // navigate("/register/plans");
      }
    } catch (error) {
      SHOW_ERROR(true, "payment failed");
      console.log("error.response.data => ", error);
    }
  };

  return (
    <div>
      {status === "loading" ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <button disabled={!props.stripe} className="btn-pay">
            Buy Now
          </button>
        </form>
      )}
    </div>
  );
};

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          onHide={props.onHide}
          elements={elements}
        />
      )}
    </ElementsConsumer>
  );
}
