import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { API, key } from "../../config";
import _ from "lodash";
import { toast } from "react-toastify";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import {
  ERROR_MESS,
  INSPECTION_PLAN_CREATE,
  INSPECTION_PLAN_DELETE,
  INSPECTION_PLAN_UPDATE,
} from "../../constant";
import { clearFileState, setCurrentNodeActionType } from "./AppUtilsSlice";

const initialState = {
  plans: [],
  plan: {},
  treeStuff: { currentNode: {}, tree: [] },
  error: null,
  actionType: null,
  status: null,
  paginationData: null,
};

export const fetchInspectionPlans = createAsyncThunk(
  "get/Plans",
  async (page) => {
    try {
      const response = await API.inspectionPlan.getAll({
        key: page ? "page" : "",
        value: page,
      });
      const { data, success } = response.data;
      // console.log("inspection plan data", response);

      return { success, data, code: 200, page };
    } catch (error) {
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const addInspectoinPlanData = createAsyncThunk(
  "post/Plan",
  async (formData, thunkAPI) => {
    try {
      const response = await API.inspectionPlan.add(formData.data);
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(success, INSPECTION_PLAN_CREATE);
        thunkAPI.dispatch(fetchOneInspectoinPlanData(data?.id));
        thunkAPI.dispatch(clearFileState());
        formData.navigate(`/inspection-plan/view/${data?.id}`, {
          replace: true,
        });
      }
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneInspectionplan = createAsyncThunk(
  "update/plan",
  async (formData, thunkAPI) => {
    try {
      const response = await API.inspectionPlan.update(
        formData.id,
        formData.finalData
      );

      const { data, success } = response.data;

      if (success) {
        thunkAPI.dispatch(setCurrentNodeActionType("v"));
        SHOW_SUCCESS(success, INSPECTION_PLAN_UPDATE);
      }

      return { data, success, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteInspectoinPlan = createAsyncThunk(
  "delete/Plan",
  async (data) => {
    try {
      const response = await API.inspectionPlan.delete(data);
      // // // console.log(response.data);
      SHOW_SUCCESS(response.data.success, INSPECTION_PLAN_DELETE);

      return data;
    } catch (error) {
      // // // console.log(error.response.data.message);
      SHOW_ERROR(error, ERROR_MESS);
      return [];
    }
  }
);
export const fetchOneInspectoinPlanData = createAsyncThunk(
  "get/Plan",
  async (id) => {
    try {
      const response = await API.inspectionPlan.getOne(id);
      const { success, data } = response.data;
      // if (success) {

      // }
      // return response.data.data;
      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const inspectionPlanSlice = createSlice({
  name: "Inpspection Plan",
  initialState,
  reducers: {
    updateTree: {
      reducer(state, action) {
        var TREE = _.cloneDeep(current(state.treeStuff.tree));

        if (action.payload.data.type === "qc" && !action.payload.data.tempkey) {
          // console.log("main tree before ", TREE);
          TREE[0].children.forEach((node) => {
            if (node.id === action.payload.stepId) {
              // console.log("node", node.children);
              node.children = [...node.children, action.payload.data];
            }
          });
        }

        if (action.payload.data.tempkey && action.payload.data.type === "qc") {
          // console.log("main tree before ", TREE);
          TREE[0].children.forEach((node) => {
            if (node.id === action.payload.stepId) {
              console.log("node", node.children);
              const objIndex = node.children?.findIndex(
                (obj) => obj.id == action.payload.data.id
              );
              console.log(objIndex);
              node.children[objIndex].label = action.payload.data.label;
            }
          });
        }

        if (
          action.payload.data.tempkey &&
          action.payload.data.type === "step"
        ) {
          const objIndex = TREE[0].children?.findIndex(
            (obj) => obj.id == action.payload.data.id
          );
          console.log(objIndex);
          TREE[0].children[objIndex].label = action.payload.data.label;
        }

        if (
          action.payload.data.type === "step" &&
          !action.payload.data.tempkey
        ) {
          console.log("main tree before ", TREE[0].children);
          TREE[0].children = [...TREE[0].children, action.payload.data];
        }

        // console.log("main tree after:", TREE);
        state.treeStuff.tree = TREE;
      },
    },
    updateQCPointArry: {
      reducer(state, action) {
        var CURR_PLAN = _.cloneDeep(current(state.plan.steps));

        // console.log("plan  before ", CURR_PLAN);
        CURR_PLAN.forEach((node) => {
          if (node.id === action.payload.stepId) {
            // console.log("QC point", node.qcPoints);
            node.qcPoints = [...node.qcPoints, action.payload.data];
            // // console.log(new_data);
          }
        });

        state.plan.steps = CURR_PLAN;
      },
    },
    updateStepArry: {
      reducer(state, action) {
        // var CURR_PLAN = _.cloneDeep(current(state.plan.steps));
        // // console.log("comming data step id :", action.payload.stepId);
        // // console.log("comming data treeData :", action.payload.data);
        // console.log("plan  before after:", CURR_PLAN);  // console.log("plan  before ", CURR_PLAN);

        // CURR_PLAN = [...CURR_PLAN, action.payload.data];

        // console.log("plan  before after:", CURR_PLAN);
        state.plan.steps.push(action.payload);
      },
    },

    removeItemTree: {
      reducer(state, action) {
        var TREE = _.cloneDeep(current(state.treeStuff.tree));

        console.log(action.payload);
        console.log("tree => ", TREE);
        if (action.payload.type === "qc" && action.payload.actionType === "r") {
          // console.log("main tree before ", TREE);
          TREE[0].children.forEach((node) => {
            // console.log("node", node.children);
            node.children = node.children.filter(
              (qc) => qc.id != action.payload.id
            );
            // console.log("node", node.children);
          });
        }

        if (
          action.payload.type === "step" &&
          action.payload.actionType === "r"
        ) {
          // console.log("main tree before ", TREE);
          TREE[0].children = TREE[0].children.filter(
            (sc) => sc.id != action.payload.id
          );
        }

        state.treeStuff.tree = TREE;
      },
    },
    // clearImage: (state, action) => {
    //   state.plan.image = null;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInspectionPlans.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchInspectionPlans.fulfilled, (state, action) => {
        const { success, data, code, page } = action.payload;

        if (success && code === 200) {
          state.status = "succeeded";

          if (page) {
            // const ProcData = data.inspectionPlans?.map((P) => {
            //   if (P.steps.length > 0) {
            //     let qcConst = P.steps
            //       .map(({ qcPoints, id }) => {
            //         if (qcPoints.length > 0) {
            //           return qcPoints.length;
            //         }
            //         return 0;
            //       })
            //       .reduce((partialSum, a) => partialSum + a, 0);
            //     return { ...P, totalQC: qcConst };
            //   }
            //   return { ...P, totalQC: 0 };
            // });
            state.plans = data.inspectionPlans;
            delete data.inspectionPlans;
            state.paginationData = data;
          } else {
            // const ProcData = data?.map((P) => {
            //   if (P?.steps?.length > 0) {
            //     let qcConst = P.steps
            //       .map(({ qcPoints, id }) => {
            //         if (qcPoints.length > 0) {
            //           return qcPoints.length;
            //         }
            //         return 0;
            //       })
            //       .reduce((partialSum, a) => partialSum + a, 0);
            //     return { ...P, totalQC: qcConst, totalSteps: P.steps?.length };
            //   }
            //   return { ...P, totalQC: 0, totalSteps: P.steps?.length };
            // });
            state.plans = data;
          }
        } else {
          state.status = "failed";
        }
      })
      .addCase(fetchInspectionPlans.rejected, (state, action) => {
        state.status = "failed";
        // console.log(action.error);
        state.error = action.payload;
      })
      // post data reduces
      .addCase(addInspectoinPlanData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addInspectoinPlanData.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          let ProcData;

          if (data?.steps?.length > 0) {
            let qcConst = data.steps
              .map(({ qcPoints, id }) => {
                if (qcPoints.length > 0) {
                  return qcPoints.length;
                }
                return 0;
              })
              .reduce((partialSum, a) => partialSum + a, 0);
            ProcData = {
              ...data,
              totalQC: qcConst,
              steps: data.steps?.length,
            };
          } else {
            ProcData = { ...data, totalQC: 0, steps: data.steps?.length };
          }

          state.plans.unshift(ProcData);
          state.status = "succeeded";
        } else {
          state.status = "succeeded";
        }
      })
      .addCase(addInspectoinPlanData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // get plan data reduces
      .addCase(fetchOneInspectoinPlanData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneInspectoinPlanData.fulfilled, (state, action) => {
        const { code, success, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          // Adding date and reactions

          // Add any fetched posts to the array
          // // // console.log(action.payload);
          state.treeStuff.tree = [
            {
              id: data?.id,
              label: data?.title,
              actionType: "v",
              children: [],
            },
          ];
          let tempChild = [];
          data?.steps?.forEach((step) => {
            let tempqc = [];
            if (step.qcPoints.length > 0) {
              step.qcPoints.forEach((qc) => {
                tempqc.push({
                  id: qc.id,
                  label: qc.title,
                  type: "qc",
                  stepId: qc.stepId,
                  actionType: "v",
                });
              });
            }

            tempChild.push({
              id: step.id,
              planId: data?.id,
              label: step.title,
              children: tempqc,
              type: "step",
              actionType: "v",
            });
          });

          state.treeStuff.tree[0].children = tempChild;
          state.plan = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(fetchOneInspectoinPlanData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // delete plan data reduces
      .addCase(deleteInspectoinPlan.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteInspectoinPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.plans =
        // const tempPLan = JSON.parse(JSON.stringify(state.plans))
        // // console.log("temp plan before :",JSON.parse(JSON.stringify(tempPLan)));
        // const aftertemp = tempPLan
        // // console.log("temp plan after :",aftertemp);

        state.plans = state.plans.filter((item) => item.id !== action.payload);
        // // console.log(JSON.parse(JSON.stringify(state.plans)));
      })
      .addCase(deleteInspectoinPlan.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneInspectionplan.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateOneInspectionplan.fulfilled, (state, action) => {
        const { data, success, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.plan = data;
        } else {
          state.status = "failed";
        }
        // console.log("updated data => ", data);
      })
      .addCase(updateOneInspectionplan.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      });
  },
});

// export const selectAllPlans = (state) => state.plans;
// export const getPostsStatus = (state) => state.status;
// export const getPostsError = (state) => state.error;

export const {
  updateTree,
  addQCPointToTree,
  addStepToTree,
  updateQCPointArry,
  updateStepArry,
  removeItemTree,
  // clearImage,
} = inspectionPlanSlice.actions;

export default inspectionPlanSlice.reducer;
