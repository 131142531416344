import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import "../../../../assets/styles.css";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripForm = (props) => {
  return (
    <div className="product">
      <Elements stripe={stripePromise}>
        <CheckoutForm onHide={props.onHide} />
      </Elements>
    </div>
  );
};

export default StripForm;
