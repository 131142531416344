import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { toast } from "react-toastify";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import { ERROR_MESS } from "../../constant";

// import TutorialDataService from "../services/tutorial.service";

const initialState = {
  currentNode: null,
  gCurrentForm: "/inspection-plan/view/",
  fileData: {},
  autoNum: 1,
  currentRole: {},
  status: "",
  flag: "",
};

export const uploadFile = createAsyncThunk(
  "image/upload",
  async (imageData) => {
    try {
      const response = await API.file.add(imageData);
      const { data, success } = response.data;
      SHOW_SUCCESS(success, "Image uploaded Successfully!");

      // console.log("file result :", res);
      return { data, success, code: 201 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const appUtilsSlice = createSlice({
  name: "AppUtils",
  initialState,
  reducers: {
    setCurrentNode: (state, action) => {
      state.currentNode = action.payload;
    },

    autoNumberGen: (state, action) => {
      const { arry, key, title } = action.payload;

      if (key === "g" && arry) {
        let tempArry = [...arry];
        if (tempArry.length > 0) {
          const tempitem = tempArry.pop();
          state.autoNum = parseInt(tempitem[title]) + 1;
        } else {
          state.autoNum = 1;
        }
      } else if (key === "r") {
        state.autoNum = 1;
      }
    },

    setCurrentNodeActionType: (state, action) => {
      state.currentNode.actionType = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },

    setgCurrentForm: (state, action) => {
      state.gCurrentForm = action.payload;
    },
    clearFileState: (state, action) => {
      state.fileData = {};
      state.flag = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadFile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;
        if (success && code === 201) {
          state.status = "succeeded";
          state.fileData = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status = "failed";
        // console.log("file uoload error ", action.payload);
        state.error = action.payload;
      });
  },
});
export const {
  setCurrentNode,
  setgCurrentForm,
  clearFileState,
  setCurrentRole,
  setCurrentNodeActionType,
  autoNumberGen,
} = appUtilsSlice.actions;

const { reducer } = appUtilsSlice;
export default reducer;
