import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from "../../../Redux/features/StripeSlice";
import StripForm from "../companyPlans/components/strip/StripForm";
import * as Yup from "yup";
import { API } from "../../../config";
import { TiTick } from "react-icons/ti";
import { MdOutlineDangerous } from "react-icons/md";

function StripModal(props) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    contactPersonFirstName: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Required"),
    contactPersonLastName: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Required"),
    contactPersonPhoneNo: Yup.string()
      .matches(/^(\+|00)[0-9]{6,14}$/, "Invalid Phone Number")
      .required("Required"),
    contactPersonEmail: Yup.string()
      .email("Enter a valid email id.")
      .required("Required"),
  });

  // const [email, setEmail] = useState();
  const [formNo, setFormNo] = useState(1);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { status, registeredCompany } = useSelector((state) => state.Stripe);
  const handleClose = () => {
    props.onHide();
    setFormNo(1);
  };
  const handleEmail = async (email) => {
    try {
      const response = await API.Stripe.verifyEmail({
        contactPersonEmail: email,
      });
      console.log("response", response.data);
      if (response.data.success) {
        setError(false);
      }
      // console.log("email", email);
      // return response.data.data;
    } catch (error) {
      setError(true);
      console.log("error", error);
    }
  };
  // console.log("error", error);

  return (
    <Modal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {formNo === 1 ? (
            <span>Company Registration</span>
          ) : (
            <span>Payment </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formNo === 1 ? (
          <>
            {status === "loading" ? (
              <h3>Loading...</h3>
            ) : (
              // company reg form
              <div>
                <Row>
                  <Formik
                    initialValues={registeredCompany}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      dispatch(
                        registerCompany({
                          companyData: values,
                          setFormNo,
                        })
                      );
                      resetForm();
                      // console.log("values", values);
                    }}
                  >
                    {({
                      errors,
                      values,
                      touched,
                      handleBlur,
                      handleChange,
                    }) => (
                      <Form>
                        <div className="form-group mb-3" controlId="noOfUsers">
                          <label className="text-uppercase">name:</label>
                          <Field
                            type="text"
                            name="name"
                            className={`form-control ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.name || ""}
                            onChange={handleChange("name")}
                            placeholder="Name of Company"
                          />
                          <ErrorMessage
                            component="span"
                            name="name"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group mb-3" controlId="noOfUsers">
                          <label className="text-uppercase">Email :</label>
                          <div className="">
                            <Field
                              className={`form-control ${
                                errors.contactPersonEmail &&
                                touched.contactPersonEmail
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onBlur={(e) => {
                                handleBlur(e);
                                let emailValue = e.currentTarget.value;
                                //check if emailValue is empty
                                if (emailValue) {
                                  handleEmail(emailValue);
                                }
                              }}
                              type="email"
                              name="contactPersonEmail"
                              value={values.contactPersonEmail || ""}
                              onChange={(e) => {
                                handleChange(e);
                                setError(null);
                              }}
                              placeholder="Email"
                            />
                            {error === false && !errors.contactPersonEmail ? (
                              <TiTick size={"2rem"} color={"green"} />
                            ) : error === true ? (
                              <span className="text-danger">
                                Enter a valid email id
                              </span>
                            ) : null}
                            <ErrorMessage
                              name="contactPersonEmail"
                              component="span"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3" controlId="noOfUsers">
                          <label className="text-uppercase">First Name :</label>
                          <Field
                            className={`form-control ${
                              errors.contactPersonFirstName &&
                              touched.contactPersonFirstName
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            name="contactPersonFirstName"
                            value={values.contactPersonFirstName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange("contactPersonFirstName")}
                            placeholder="First name"
                          />
                          <ErrorMessage
                            name="contactPersonFirstName"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group mb-3" controlId="noOfUsers">
                          <label className="text-uppercase"> Last Name :</label>
                          <Field
                            className={`form-control ${
                              errors.contactPersonLastName &&
                              touched.contactPersonLastName
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            name="contactPersonLastName"
                            value={values.contactPersonLastName || ""}
                            onChange={handleChange("contactPersonLastName")}
                            placeholder="Last name "
                          />
                          <ErrorMessage
                            name="contactPersonLastName"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group mb-3" controlId="noOfUsers">
                          <label className="text-uppercase">
                            {" "}
                            Person Phone No :
                          </label>
                          <Field
                            className={`form-control ${
                              errors.contactPersonPhoneNo &&
                              touched.contactPersonPhoneNo
                                ? "is-invalid"
                                : ""
                            }`}
                            type="tel"
                            name="contactPersonPhoneNo"
                            value={values.contactPersonPhoneNo || ""}
                            onChange={handleChange("contactPersonPhoneNo")}
                            placeholder="Person Phone Number"
                          />
                          <ErrorMessage
                            name="contactPersonPhoneNo"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={error}
                          >
                            Next
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Row>
              </div>
            )}
          </>
        ) : (
          <>
            <StripForm onHide={handleClose} />
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => setFormNo(1)}
            >
              Back
            </button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default StripModal;
