import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import {
  ERROR_MESS,
  ROLE_CREATE,
  ROLE_DELETE,
  ROLE_UPDATE,
} from "../../constant";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";

const initialState = {
  Roles: [],
  Role: {},
  error: null,
  status: null,
};

export const getAllCompanyRoles = createAsyncThunk(
  "get/CompanyRoles",
  async (formData) => {
    try {
      const response = await API.companyRoles.getAll();
      return response.data.data;
    } catch (error) {
      console.log(error.response.message);
      return error.response;
    }
  }
);
export const addCompanyRolesData = createAsyncThunk(
  "post/CompanyRoles",
  async (data) => {
    try {
      const response = await API.companyRoles.add(data.values);
      if (response.data.success) {
        SHOW_SUCCESS(response.data.success, ROLE_CREATE);
        data.resetForm();
      }

      return [];
    } catch (error) {
      // // // console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty("name")) {
        SHOW_ERROR(true, errors.name[0]);
      } else {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneCompanyRole = createAsyncThunk(
  "update/CompanyRole",
  async (data) => {
    try {
      // // console.log("from slice :", data);
      const response = await API.companyRoles.update(data.id, data.finalData);
      SHOW_SUCCESS(response.data.success, ROLE_UPDATE);
      return [];
    } catch (error) {
      // // console.log("FROM SLICE API ERROR", error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty("name")) {
        SHOW_ERROR(true, errors.name[0]);
      } else {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return [];
    }
  }
);

export const deleteCompanyRole = createAsyncThunk(
  "delete/CompanyRole",
  async (data) => {
    try {
      const response = await API.companyRoles.delete(data);
      SHOW_SUCCESS(response.data.success, ROLE_DELETE);
      return data;
    } catch (error) {
      // // console.log(error.response.data.message);
      SHOW_ERROR(error, ERROR_MESS);
      return [];
    }
  }
);
export const fetchOneCompanyRoleData = createAsyncThunk(
  "get/CompanyRole",
  async (id) => {
    try {
      const response = await API.companyRoles.getOne(id);
      // // console.log(response.data);

      return response.data;
    } catch (error) {
      // // console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

// export const addNewPost = createAsyncThunk('posts/addNewPost', async (initialPost) => {
//   const response = await axios.post(POSTS_URL, initialPost)
//   return response.data
// })

const CompanyRoleSlice = createSlice({
  name: "Company Roles",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCompanyRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllCompanyRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions

        // Add any fetched posts to the array
        // // // console.log(action.payload.inspectionPlans);
        state.Roles = action.payload;
      })
      .addCase(getAllCompanyRoles.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload.error;
      })
      // post data reduces
      .addCase(addCompanyRolesData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addCompanyRolesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        // // // console.log(action.payload);
      })
      .addCase(addCompanyRolesData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchOneCompanyRoleData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneCompanyRoleData.fulfilled, (state, action) => {
        state.status = "succeeded";
        // // // console.log(action.payload);
        state.Role = action.payload.data;
      })
      .addCase(fetchOneCompanyRoleData.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // delete plan data reduces
      .addCase(deleteCompanyRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCompanyRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions
        state.Roles = state.Roles.filter((item) => item.id !== action.payload);
        // Add any fetched posts to the array
        // // // console.log(action.payload);
        // state.plans = action.payload.inspectionPlans;
      })
      .addCase(deleteCompanyRole.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      });
  },
});

export default CompanyRoleSlice.reducer;
