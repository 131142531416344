import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setgCurrentForm,
  setCurrentNode,
  clearFileState,
} from "../../../Redux/features/AppUtilsSlice";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import {
  removeItemTree,
  updateActionType,
} from "../../../Redux/features/InspectionPlanSlice";
import {
  deleteStep,
  fetchOneStep,
  stepCleaner,
} from "../../../Redux/features/StepSlice";
import {
  deleteQCPoint,
  QCPointCleaner,
} from "../../../Redux/features/QCPointSlice";

const Node = ({ item, hasChildren, level, onToggle, selected }) => {
  const dispatch = useDispatch();
  // console.log("selected", level);

  const { permissions } = useSelector((state) => state.Auth);

  const handelTreeClick = (actionType) => {
    const NEW_ITEM = { ...item, actionType };
    console.log("item", NEW_ITEM);
    // qc point
    if (level === 2) {
      if (actionType === "r") {
        dispatch(deleteQCPoint(NEW_ITEM?.id));
        console.log(NEW_ITEM);
        dispatch(removeItemTree(NEW_ITEM));
        dispatch(setgCurrentForm("/inspection-plan/step"));
        dispatch(
          setCurrentNode({
            actionType: "v",
            id: NEW_ITEM?.stepId,
            type: "step",
          })
        );
      } else {
        dispatch(setgCurrentForm("/inspection-plan/qcpoint"));
        dispatch(clearFileState());
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }

    // step
    if (level === 1) {
      if (actionType === "a") {
        dispatch(QCPointCleaner());
        dispatch(stepCleaner());
        dispatch(fetchOneStep(NEW_ITEM.id));
        dispatch(setgCurrentForm("/inspection-plan/qcpoint"));
        dispatch(setCurrentNode(NEW_ITEM));
        dispatch(clearFileState());
      } else if (actionType === "r") {
        dispatch(deleteStep(NEW_ITEM?.id));
        console.log("step delete", NEW_ITEM);
        dispatch(removeItemTree(NEW_ITEM));
        dispatch(setgCurrentForm("/inspection-plan/view/"));
        dispatch(
          setCurrentNode({
            actionType: "v",
            id: NEW_ITEM?.planId,
            type: "plan",
          })
        );
      } else {
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/step"));
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }
    if (level === 0) {
      if (actionType === "a") {
        dispatch(stepCleaner());
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/step"));
        dispatch(setCurrentNode(NEW_ITEM));
      } else {
        console.log(NEW_ITEM);
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/view/"));
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }
  };
  return (
    <div
      className="pointer mx-n2 mt-n2 mb-2"
      style={{
        paddingLeft: ` ${level <= 0 ? `${12}px` : `${level * 35}px`}`,
        backgroundColor: `${hasChildren ? "white" : "#F9F9F9"}`,
        border: `${hasChildren ? "none" : "1px solid #EEEEEE"}`,
        paddingTop: "7px",
        paddingBottom: "7px",
        overflow: "auto",
      }}
    >
      <span onClick={() => handelTreeClick("v")} className="pointer">
        <p style={{ overflowX: "auto" }} className="d-inline">
          {item.label}
        </p>
      </span>

      <div
        onClick={onToggle}
        className={`${hasChildren ? " " : "d-none"} pointer d-inline float-end`}
      >
        {selected ? (
          <IoIosArrowDown size="1rem" />
        ) : (
          <IoIosArrowForward size="1rem" />
        )}
      </div>
      <div className={` pointer d-inline float-end`}>
        <span className={`${level === 0 ? " " : "d-none"}`}>
          {permissions?.includes("Step-Create") ? (
            <CgAdd
              onClick={() => handelTreeClick("a")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="add"
              // color="#0C4688"
            />
          ) : null}
          {permissions?.includes("InspectionPlan-Edit") ? (
            <BiEditAlt
              onClick={() => handelTreeClick("e")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="edit"
              className="me-1 ms-2"
              // color="#0000001A"
            />
          ) : null}
        </span>

        <span className={`${level === 1 ? " " : "d-none"}`}>
          {permissions?.includes("QCPoint-Create") ? (
            <CgAdd
              onClick={() => handelTreeClick("a")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="add"
              // color="#0C4688"
            />
          ) : null}
          {permissions?.includes("Step-Edit") ? (
            <BiEditAlt
              onClick={() => handelTreeClick("e")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="edit"
              className="me-1 ms-2"
              // color="#0000001A"
            />
          ) : null}
          {permissions?.includes("Step-Delete") ? (
            <RiDeleteBin6Line
              onClick={() => handelTreeClick("r")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="delete"
              className="me-1 ms-2"
              // color="#AA4A44"
            />
          ) : null}
        </span>

        <span className={`${level === 2 ? " " : "d-none"}`}>
          {permissions?.includes("QcPoint-Edit") ? (
            <BiEditAlt
              onClick={() => handelTreeClick("e")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="edit"
              className="me-1 ms-2"
              // color="#0000001A"
            />
          ) : null}
          {permissions?.includes("QcPoint-Delete") ? (
            <RiDeleteBin6Line
              onClick={() => handelTreeClick("r")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="delete"
              className="me-1 ms-2"
              // color="#AA4A44"
            />
          ) : null}
        </span>
      </div>
      {/* <span className={`${hasChildren ? "d-none" : ""} pointer float-end`}>
        <FaEdit
          onClick={() => handelTreeClick("e")}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="edit"
          className="ms-2"
          color="#0000001A"
        />
      </span> */}
    </div>
  );
};

export default Node;
// {/* <i class='bx bx-chevron-right'></i> */ }

// {/* <i class='bx bx-chevron-down' ></i> */}
