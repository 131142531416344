import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { AiOutlineUser } from 'react-icons/ai';
import { BsPower, BsUnlockFill } from "react-icons/bs";
import { logoutUser, passwordReset } from "../../../Redux/features/AuthSlice";
// import { key } from "../../../config";
import SoloFormLoading from "../../common/SkeltonLoading/SoloFormLoading";
import PasswordUpdate from "./PasswordUpdate";
import Dropdown from "react-bootstrap/Dropdown";
import { fetchOneCompanyData } from "../../../Redux/features/CompanyManagementSlice";
import { BiUser } from "react-icons/bi";
const Header = ({ sideBarToggle, setSideBarToggle }) => {
  const navigate = useNavigate();

  const {
    Auth: { Authenticate, currentUser, status },
    CompanyManagement: { company },
  } = useSelector((state) => state);

  console.log("");

  const dispatch = useDispatch();
  const [modelOpen, setModelOpen] = React.useState(false);
  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  // const navigate = useNavigate()
  const logOut = () => {
    dispatch(logoutUser());
    navigate("/login", { state: { authKey: "log-out" } });
  };

  // useEffect(() => {
  //   const company = localStorage.getItem("companyId");
  //   if (company) {
  //     dispatch(fetchOneCompanyData(company));
  //   }
  //   // console.log("company", company);
  // }, [dispatch]);

  // navigate('/login')
  return (
    <>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-fluid">
          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <Link
              className="nav-item nav-link px-0 me-xl-4"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#layout-menu"
            >
              {sideBarToggle && (
                <i
                  className="bx bx-menu bx-sm"
                  onClick={() => setSideBarToggle(false)}
                />
              )}
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
