import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API, key } from "../../config";
import { ERROR_MESS } from "../../constant";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import { autoNumberGen, setCurrentNodeActionType } from "./AppUtilsSlice";
import {
  updateActionType,
  updateQCPointArry,
  updateStepArry,
  updateTree,
} from "./InspectionPlanSlice";

const initialState = {
  step: {},
  error: null,
  status: null,
};

export const addStep = createAsyncThunk(
  "post/step",
  async (fromData, thunkAPI) => {
    try {
      const response = await API.step.add(fromData.finalData);
      const { data, success } = response.data;

      if (success) {
        SHOW_SUCCESS(success, "A new step added successfully.");
        // fromData.setStepFormData(key.initialState.STEP.STATE);
        thunkAPI.dispatch(updateStepArry(data));
        // thunkAPI.dispatch(autoNumberGen({ arry: [], key: "r" }));
        thunkAPI.dispatch(setCurrentNodeActionType("v"));
        thunkAPI.dispatch(
          updateTree({
            stepId: null,
            data: {
              id: data.id,
              label: data?.title,
              children: [],
              type: "step",
            },
          })
        );

        thunkAPI.dispatch(
          updateQCPointArry({
            stepId: null,
            data: data,
          })
        );
      }

      return { data, success, code: 201 };
    } catch (error) {
      console.log("error => ", error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateStep = createAsyncThunk(
  "put/step",
  async (formdData, thunkAPI) => {
    try {
      const response = await API.step.update(formdData.id, formdData.data);
      // // console.log(response.data);
      if (response.data.success) {
        // if (true) {
        toast.success("Step updated successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        thunkAPI.dispatch(setCurrentNodeActionType("v"));
      }
      thunkAPI.dispatch(
        updateTree({
          stepId: null,
          data: {
            tempkey: true,
            id: response.data.data.id,
            label: response.data.data?.title,
            children: [],
            type: "step",
            actionType: "v",
          },
        })
      );

      thunkAPI.dispatch(
        updateQCPointArry({
          stepId: null,
          data: response.data.data,
        })
      );

      return response.data.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const fetchOneStep = createAsyncThunk("get/step", async (id) => {
  try {
    const response = await API.step.getOne(id);
    // // console.log(response.data);
    const { data, success } = response.data;

    return { data, success, code: 200 };
  } catch (error) {
    // // console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const deleteStep = createAsyncThunk("delete/step", async (data) => {
  try {
    const response = await API.step.delete(data);
    if (response.data.success) {
      // if (true) {
      toast.success("Step deleted successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return data;
  } catch (error) {
    console.log("error", error);
    SHOW_ERROR(error, ERROR_MESS);
  }
});

const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    stepCleaner: {
      reducer(state, action) {
        // console.log("step cleaning... ");
        state.step = {};
        // console.log("step cleaning done !");
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addStep.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addStep.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;
        if (success && code === 201) {
          state.status = "succeeded";
          state.step = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(addStep.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })
      // get plan data reduces
      .addCase(fetchOneStep.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneStep.fulfilled, (state, action) => {
        const { code, success, data } = action.payload;

        if (success && code === 200) {
          let points = [];
          data.qcPoints?.forEach((qc) => {
            // console.log("data(qc) => ", qc);
            if (qc.y !== null) {
              points.push({ top: qc.y, left: qc.x, order: qc.order });
            }
          });
          // console.log("points => ", points);
          let newData = { ...data, points };
          state.step = newData;
          state.status = "succeeded";
        } else {
          state.status = "failed";
        }
      })
      .addCase(fetchOneStep.rejected, (state, action) => {
        state.status = "failed";
        // // // console.log(action.error);
        state.error = action.payload;
      })

      .addCase(deleteStep.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteStep.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.step = {};
      })
      .addCase(deleteStep.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      });
  },
});

// export const selectAllPlans = (state) => state.plans;
// export const getPostsStatus = (state) => state.status;
// export const getPostsError = (state) => state.error;

export const { stepCleaner } = stepSlice.actions;

export default stepSlice.reducer;
