import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { handelState } from "../../../../utils/handelStates";
import Spinner from "../../../common/Spinner";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import {
  addCategory,
  getAllCatogries,
} from "../../../../Redux/features/CategorySlice";

// import ToggleButton from "react-toggle-button";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

const CreateApprovalCategories = () => {
  // const navigate = useNavigate();

  const initState = {
    name: "",
    parentId: null,
  };

  // const [rejectionData, setRejectionData] = useState({
  //   code: "",
  //   reason: "",
  //   parentId: null,
  // });
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const {
    Catogries: { allCategories, status },
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getAllCatogries());
  }, [dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(addRejectedReasonsData(rejectionData));
  //   navigate("/rejection-reasons");
  // };

  // if (status === "loading") {
  //   return <Spinner />;
  // }
  return (
    <>
      <div className="row">
        <MainBreadcrum
          name="Approval Category"
          subName=""
          links={[
            { path: "/", title: "" },
            { path: "/approval-categories", title: "Approval Category" },
            { path: "", title: "Create", activeLink: true },
          ]}
        />
      </div>
      {status === "loading" ? (
        <Spinner />
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <Formik
                  initialValues={initState}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    // e.preventDefault();
                    // // console.log(values);
                    dispatch(addCategory(values));
                  }}
                >
                  {({ errors, values, touched, handleBlur }) => (
                    <Form>
                      <div className="mb-3 row">
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="name"
                        >
                          Catogory Name:<span className="text-danger"> *</span>
                        </label>
                        <div className="col-sm-10">
                          <Field
                            name="name"
                            type="text"
                            className={`form-control ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            placeholder="Categories"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      {/* parent Id  */}
                      <div className="mb-3 row">
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="parentId"
                        >
                          Parent:
                        </label>
                        <div className="col-sm-10">
                          <Field
                            as="select"
                            name="parentId"
                            className="form-select"
                          >
                            <option value="" selected>
                              Select Parent
                            </option>
                            {allCategories?.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 align-self-center text-center">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* <div className="col-md-6"></div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateApprovalCategories;
